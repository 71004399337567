:root {
  --theme-navpurple: #e6e6e6;
  --theme-Bg: #c2d5e2;
  --theme-heroBg: #bdbdee;
  --theme-otherBg: #2a52a6;
  --theme-primary-blue: #2a52a6;
  --theme-secondary-blue: #362c98;
  --theme-primary-bg: #ecf1f5;
  --theme-primary-gray: #4f4f4f;
}

.hero-bg {
  background-color: var(--theme-heroBg);
}

.other-bg {
  background-color: var(--theme-otherBg);
}

.theme-purple {
  background-color: var(--theme-navpurple) !important;
}

.theme-purple-text {
  color: var(--theme-navpurple) !important;
}
.btn-primary {
  background: linear-gradient(
    180deg,
    rgba(73, 60, 201, 1) 0%,
    rgba(37, 29, 123, 1) 100%
  ) !important;
  border: 0px;
  border-radius: 10px;
  outline: none;
  font-weight: 500;
}

.card {
  border-radius: 15px;
  color: white;
}

.card-pos {
  margin-top: 4rem;
}

.custom-container {
  padding: 0rem 2rem;
}

body {
  overflow: auto;
  background-color: var(--theme-Bg);
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
