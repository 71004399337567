.up-card-container {
  margin-top: 2rem;
}

.up-card-container .card {
  background-color: var(--theme-primary-bg);
}

.up-card-container .card .body {
  padding: 0rem 2rem;
}

.up-card-container .header {
  color: var(--theme-primary-blue) !important;
  background-color: white;
  border-radius: 15px 15px 0px 0px;
  padding: 0.5rem 1rem;
  font-size: 0.8em;
  font-weight: 800;
}

.theme-primary-blue-text {
  color: var(--theme-primary-blue);
}

.theme-primary-blue-bg {
  background-color: var(--theme-primary-blue);
}

.theme-primary-gray-text {
  color: var(--theme-primary-gray);
  font-size: 0.8em;
}
.theme-primary-gray-bg {
  background-color: var(--theme-primary-gray);
}

.notes-by-hiring-tool {
  color: black;
  font-size: 0.8em;
  margin-top: 1rem;
}

.notes-by-hiring-tool ul {
  list-style: none;
  line-height: 2rem;
}

.notes-by-hiring-tool ul li span {
  font-weight: 800;
}

.other-instruction {
  color: black;
  font-size: 0.8em;
  color: var(--theme-primary-gray);
}

.start-int-btn {
  text-align: center;
}

@media only screen and (max-width: 768px) {
}
