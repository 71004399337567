.admin-hero-container {
  margin-top: 2rem;
  color: var(--theme-primary-blue);
}

.admin-hero-container > .card {
  background-color: var(--theme-primary-blue);
  padding: 1rem 2rem;
}

.admin-hero-container .card p {
  font-size: 1.7em;
}

.admin-hero-container .card div {
  margin-left: 2rem;
}

.list-group-position {
  border-radius: 10px;
}

.admin-home-btn {
  padding: 0rem 5rem;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .admin-hero-container .card p {
    font-size: 1rem;
  }
  .admin-home-btn {
    flex-direction: column;
    padding: 0rem;
    margin-top: 0rem;
  }
  .admin-home-btn button {
    margin: 0 auto;
    margin-top: 0.5rem;
    max-width: 15rem;
    font-size: 0.7rem;
    padding: 0.2rem 0.7rem;
    border-radius: 5px;
  }
}

/* Modal Styles */
.hover {
  cursor: pointer;
}

.time-input {
  display: flex;
}

.time-input span {
  padding: 0 5px;
}
