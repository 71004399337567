.card-headline {
  background-color: red;
  padding-top: 0.7rem;
  color: var(--theme-primary-blue);
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.details {
  background-color: var(--theme-primary-bg);
}

.position-details {
  padding-top: 1rem;
  margin: 0 auto;
}

.position-details div:nth-child(1) {
  font-weight: 400;
}

.answered-total {
  min-height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.answered-question > div > div > p {
  color: var(--theme-primary-gray);
}

.read-more-button {
  color: blue;
}

.hover:hover {
  cursor: pointer;
}

.underlineAnnotation {
  max-height: 200px;
}