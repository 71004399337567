.hero {
  margin-top: 7rem;
}

.hero > div > p {
  line-height: 2rem;
  font-weight: 500;
}

.hero-desc {
  color: #969fad;
}

.google-btn-container {
  margin-top: 2rem;
}

.google-btn-container .btn {
  background-color: white;
}

@media only screen and (max-width: 767px) {
  .custom-container {
    padding: 0 2rem;
  }
  .hero {
    text-align: center;
  }
  .google-btn-container div {
    text-align: center;
  }
}
