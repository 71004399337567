.up-player-container {
  padding: 0 2rem;
  margin-top: 2rem;
}

.up-player-container p {
  color: #575353;
  margin: 0 auto;
  font-size: 0.7rem;
  max-width: 25rem;
}

.up-player-container .card {
  margin: 0 auto;
  max-width: 25rem;
}

.up-player-container .row {
  padding: 0.6rem;
}

.up-player-container .row > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic-circle {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  border-radius: 50%;
  color: white;
}

.mic-circle:hover {
  cursor: pointer;
}

.up-player-container .row div:nth-child(2) {
  font-weight: 900;
  color: #575353;
  display: flex;
  flex-direction: column;
}

.up-player-container .row div:nth-child(2) span {
  font-weight: 400;
  font-size: 0.5em;
}

.bar {
  height: 2px;
  width: 100%;
  background-color: #575353;
  display: flex;
  align-items: center;
}

.progress {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #26d6c5;
}

.prog {
  height: 2px;
  background-color: #26d6c5;
}
