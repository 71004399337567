.bg-dark {
  background-color: white !important;
}

nav span {
  color: #2a52a6;
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.icons {
  color: #2a52a6;
  font-size: 2rem;
}

.icons > a {
  margin-right: 3rem;
  color: #2a52a6;
}

.icons > a:hover {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .icons {
    font-size: 1rem;
  }

  .icons > a {
    margin-right: 1.5rem;
  }
}
