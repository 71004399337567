.vol-slider span input {
  min-width: 50px;
  transform: rotate(-90deg);
}

.vol-slider {
  position: relative;
}

.vol-slider ul {
  border-radius: 7px;
  list-style: none;
  position: absolute;
  top: 2rem;
  z-index: 2;
  right: -1rem;
}

.vol-slider ul li {
  background-color: rgb(0, 0, 0, 0.7);
  min-width: 3.5rem;
  text-align: center;
}

.vol-slider ul li:first-child {
  border-radius: 7px 7px 0 0;
}

.vol-slider ul li:last-child {
  border-radius: 0px 0px 7px 7px;
}
