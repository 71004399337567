.openings {
  margin-top: 1.5rem;
}

.openings > h5 {
  color: var(--theme-secondary-blue);
}

.openings .card {
  color: var(--theme-secondary-blue);
  font-size: 0.8em;
  font-weight: 500;
  padding: 1rem;
  line-height: 2rem;
}

.openings .card button {
  font-size: 0.7rem;
  max-width: 60%;
}

.openings table {
  margin-top: 2rem;
  text-align: center;
}

.openings table thead {
  font-weight: 500;
}

.openings .card li {
  font-size: medium;
}

@media only screen and (max-width: 767px) {
  .custom-container {
    padding: 0 1rem;
  }

  .openings table {
    font-size: 0.9em;
  }

  .openings > h5 {
    text-align: center;
  }
}
