.player-container {
  width: 100%;
  display: flex;
  margin: 0 auto;
  height: 3rem;
  padding: 0px;
  background-color: white;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 5fr 2fr;
  position: relative;
}

.clock {
  color: white;
  height: 100%;
  border-radius: 15px 0px 0px 15px;
  background-color: blue;
  min-width: 2rem;
  text-align: center;
}

.clock i {
  font-size: 1rem;
}

.clock h6 {
  font-size: 1rem;
  justify-content: center;
}

.pause-play {
  height: 100%;
  background-color: white;
  display: grid;
  place-content: center;
}

.pause-play-btn {
  width: 2rem;
  height: 2rem;
  background-color: #c4c4c4;
  display: grid;
  place-content: center;
  border-radius: 50%;
}

.pause-play-btn:hover {
  cursor: pointer;
}

.start-btn {
  background-color: red;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.stop-btn {
  background-color: #000;
  width: 1.2rem;
  height: 1.2rem;
}

.timer {
  display: flex;
  align-items: center;
}

.play-slider {
  background-color: white;
  display: grid;
  place-content: center;
}

.play {
  background-color: pink;
  max-width: 2rem;
  max-height: 2rem;
}

.delete {
  background-color: white;
  border-radius: 0px 15px 15px 0px;
  display: grid;
  color: red;
  place-content: center;
}

.delete:hover {
  cursor: pointer;
}

.graph {
  z-index: 1;
  position: absolute;
  max-width: 100px;
  top: 20px;
  opacity: 0.1;
}

#track {
  width: 300px;
  background-color: #fff;
  max-height: 40px;
}

@media only screen and (max-width: 600px) {
  .player-container {
    grid-template-columns: 3fr 2fr 2fr 3fr 2fr;
    width: 100%;
  }

  .timer {
    font-size: 0.7em;
  }

  .clock p {
    display: none;
  }

  #track {
    width: 150px;
    background-color: #fff;
    max-height: 40px;
  }
  .pause-play-btn {
    margin-left: 10px;
  }
}
