.success-submit {
  color: var(--theme-primary-blue);
  margin-top: 2rem;
  padding: 1rem;
}

.success-submit .card {
  margin: 2rem auto;
  color: var(--theme-primary-blue);
  padding: 1rem;
  max-width: 50rem;
  font-size: 1.1em;
}

.dashboard-btn {
  margin: 0 auto;
  margin-top: 2rem;
}
