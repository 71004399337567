.footer-btn {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.footer-btn button:nth-child(2) {
  margin-left: 2rem;
}

.btn-danger {
  border-radius: 8px !important;
}

@media only screen and (max-width: 767px) {
  .footer-btn {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 2rem auto;
  }
  .footer-btn .btn-primary {
    font-size: 1rem;
    font-weight: 400;
  }

  .footer-btn button:nth-child(2) {
    margin-left: 0rem;
    margin-bottom: 1rem;
  }

  .footer-btn button:nth-child(1) {
  }
}
