#que-counter {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
@media only screen and (max-width: 900px) {
  #que-counter {
    position: relative;
    text-align: center;
  }
}
