.details-hover:hover {
  cursor: pointer;
}


.defaultCSS {
  background-color: #fff !important;
  color: #000;
}

thead .defaultCSS{
   color: #000 !important;
}

.search {
  border: 1px solid !important;
  border-radius: 2px !important;
}

.SortableTblTd__Wrap-sc-1qvsh6x-0 {
  text-align: center;
}

.SortableTbl__SortableTblStyled-gpkizf-0 {
  min-height: 25rem;
  position: relative;
}

.SortableTblPager__Wrap-tl0i7h-0 button {
  background: linear-gradient( 180deg, rgba(73, 60, 201, 1) 0%, rgba(37, 29, 123, 1) 100% ) !important;
  border: 0px;
  border-radius: 10px;
  outline: none;
  font-weight: 500;
}

.dropdownMenu {
  background-color: #fff;
}

.MuiDataGrid-row {
  cursor: pointer;
}