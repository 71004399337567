.bread-comp {
  margin-top: 3rem;
}

.breadcrumb {
  position: relative;
}

.bg-line {
  position: absolute;
  top: -2px;
  width: 100%;
  background-color: black;
  margin-top: 1.5rem;
  z-index: -1;
}

.breadcrumb > li {
  max-width: 11.5rem;
  background-color: var(--theme-heroBg);
  color: var(--theme-primary-gray);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.breadcrumb > li > span {
  align-items: center;
  align-self: center;
}

.breadcrumb .completed {
  background-color: var(--theme-primary-gray);
  color: white;
}

.breadcrumb .active {
  background-color: var(--theme-primary-blue);
  color: white;
}

.bread-comp > div {
  width: 80%;
}