.que-header .card {
  color: black;
}

.time-remaining {
  max-width: 23rem;
  margin: 2rem auto;
}
.time-remaining .card {
  padding: 0.8rem;
  font-size: 1.3em;
}

.time-remaining .card .row div:nth-child(3) {
  font-weight: 800;
  color: red;
}

.question-remaining-time {
  font-size: 0.7em;
  margin-top: -2rem;
}

.question-remaining-time .card {
  color: var(--theme-primary-blue);
  max-width: 7rem;
  margin: 0 auto;
}

.question-card {
  margin-top: 2rem;
  padding: 0rem 2rem;
  background-color: white;
  padding: 1rem;
  border-radius: 15px;
  min-height: 5rem;
}
.question-card p {
  font-size: 1.3em;
}
.question-card h3 {
  color: var(--theme-primary-blue);
}

@media only screen and (max-width: 767px) {
  .question-card {
    background-color: white;
    padding: 1rem;
    border-radius: 15px;
  }
  .question-card h3 {
    color: var(--theme-primary-blue);
  }
  .question-card p {
    font-size: 1em;
  }
}
